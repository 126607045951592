import React, { useMemo } from "react";
import {
  useQueryParam,
  StringParam,
} from "use-query-params";
import { graphql } from "gatsby";
import usePrismicFinancialEducation from "../../hooks/usePrismicFinancialEducation";
import SinglePageTemplate from "../../components/singlePageTemplate";

const FinancialEvents = () => {
  const [id] = useQueryParam(
    "id",
    StringParam
  );
  const data =
    usePrismicFinancialEducation();
  const events = useMemo(
    () =>
      data.slideData.find(
        (item) => item.id === id
      ),
    [id]
  );

  return (
    <SinglePageTemplate
      title={events?.title}
      route="events"
      image={events?.image}
      description={events?.description}
      usefulLinks={events?.usefulLinks}
    />
  );
};

export default FinancialEvents;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
